export const MAIN_PAGE_ROUTE = '/admin'
export const LOGIN_PAGE_ROUTE = '/admin/login'
export const LOGUP_PAGE_ROUTE = '/admin/logup'
export const FORGOT_PASSWORD_PAGE_ROUTE = '/admin/forgot-password'
export const RESET_PASSWORD_PAGE_ROUTE = '/admin/reset-password'
export const PROFILE_PAGE_ROUTE = '/admin/profile'


export const adminRoutes = {
    profile: {
        index: '/admin/profile'
    },
    users: {
        index: '/admin/users',
        create: '/admin/users/create',
        edit: {
            index: (id) => `/admin/users/${id ? id : ":id"}/edit`
        },
        seller: {
            index: '/admin/users/sellers'
        },
        client: {
            index: '/admin/users/clients',
            edit: {
                index: (id) => `/admin/users/clients/${id ? id : ":id"}/edit/*`,
                index2: (id) => `/admin/users/clients/${id ? id : ":id"}/edit`
            }
        }
    },
    adverts : {
        index: '/admin/adverts',
        edit: {
            index: (id) => `/admin/adverts/${id ? id : ":id"}/edit`
        }
    },
    transactions: {
        index: '/admin/transactions'
    },
}