import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import AdvertsServices from '../../../services/admin/AdvertsServices';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from '../../../components/simple/FormGroup';
import { Collapse } from 'react-bootstrap';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AdvertStat = () => {
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [queryString, setQueryString] = useState('');
    const [open, setOpen] = useState(false);

    const {t} = useTranslation();

    const [data, setData] = useState();

    const fetchInfo = useCallback(async () => {
        const res = await AdvertsServices.stat(queryString);

        if (res.statusCode === 200) {
            setInfo(res.content)
        } else {
            //toast.error(res.message)
        }

        setLoading(false);
    }, [queryString])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchInfo()
    }, [fetchInfo]);

    useEffect(() => {
        if(info?.items){
            setFromDate(info.from);
            setToDate(info.to);
            let arr = info.items.map(it => it.title);
            let arr2 = info.items.map(it => it.value);
            setData({
                labels: [...arr],
                datasets: [
                  {
                    label: t('labels.views'),
                    data: [...arr2],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    pointStyle: 'circle',
                    pointRadius: 10,
                    pointHoverRadius: 15,
                    dataLabels: {
                        color: 'pink'
                    },
                  },
                ],
            })
        }
    }, [info])

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: t('labels.stat_title'),
          },
        },
        scales: {
            x: {

            },
            y:{
                ticks: {
                    padding: 10,
                    crossAlign: 'start'
                  }
            }
        }
    };

    const changeFromDate = (e) => {
        setFromDate(e.target.value);
    }

    const changeToDate = (e) => {
        setToDate(e.target.value);
    }

    const applyDate = () => {
        setQueryString(`from=${fromDate}&to=${toDate}`);
    }

  return (
    data &&
    <div className="row row-sm mb-4">
		<div className="col-md-12 col-lg-12">
			<div className="card">
                <div className="card-header border-bottom-0 pb-3" onClick={() => setOpen(!open)}>
                    <div className="d-flex justify-content-between">
                        <label className="main-content-label my-auto pt-2">{t('labels.stat_title')}</label>
                        <i className={open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i>
                    </div>
                </div>
                <Collapse in={open}>
                    <div className='card-body row'>
                        <div className="col-8">
                            <div className='line-canvas'>
                                <Line options={options} data={data}/>
                            </div>
                        </div>
                        <div className="col-4 filter-canvas">
                            <div className="row">
                                <div className="col-12">
                                    <FormGroup label={t('labels.from_date')}>
                                        <input type="date" className="form-control2 pr-10" placeholder={t('labels.from_date')} value={fromDate} onChange={changeFromDate}/>
                                    </FormGroup>
                                </div>
                                <div className="col-12">
                                    <FormGroup label={t('labels.to_date')}>
                                        <input type="date" className="form-control2 pr-10" placeholder={t('labels.to_date')} value={toDate} onChange={changeToDate}/>
                                    </FormGroup>
                                </div>
                                <div className="col-12">
                                    <div className='row justify-content-end'>
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-secondary" onClick={applyDate}>
                                                {t('labels.apply')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    </div>
  )
}

export default AdvertStat