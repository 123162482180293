import React, { useState } from "react";

const ThemeSwitch = () => {
    const [isDark, setIsDark] = useState();

    const changeTheme = () => {
        if(isDark){
            document.body.className = 'light-theme';
        }else{
            document.body.className = 'dark-theme';
        }
        setIsDark(!isDark)
    }
  return (
    <div className="dropdown d-flex main-header-theme" onClick={() => changeTheme()}>
        <a className="nav-link icon layout-setting">
            <span className="dark-layout">
                <i className="fe fe-sun header-icons"></i>
            </span>
            <span className="light-layout">
                <i className="fe fe-moon header-icons"></i>
            </span>
        </a>
    </div>
  );
};

export default ThemeSwitch;
