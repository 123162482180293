import React from 'react';
import ReactPaginate from "react-paginate";

const Pagination = ({pageCount, params, loading, handlePageClick}) => {
    return (
        pageCount > 1 &&
        <ReactPaginate
            previousLabel={<div className="page-item">Prev</div>}
            nextLabel={<div className="page-item">Next</div>}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            initialPage={params.get('page') ? params.get('page') - 1 : 0}
            disableInitialCallback={true}
            onPageChange={handlePageClick}
            containerClassName={`pagination2 ${loading ? 'isLoading' : ''}`}
            activeClassName={'active2'}
            forcePage={params.get('page') ? params.get('page') - 1 : 0}
        />
    );
};

export default Pagination;