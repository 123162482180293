import React, {useEffect, useState} from 'react';
import Select from "react-select";
import {useTranslation} from "react-i18next";

const styles = {
    control: (provided, state) => ({
        ...provided,
        height:'40px',
        borderRadius: '4px',
        fontSize: '14px',
        fontFamily: 'inherit',
        backgroundColor: state.isDisabled ? '#F4F5F7' : '#fff',
        boxShadow: 'none',
        border: state.selectProps.$error ? '1px solid red' : (state.isFocused ? '1px solid var(--primary-bg-color)' : '1px solid var(--dark-color)'),
        
        '&:hover': {
            borderColor: state.selectProps.$error ? 'red' : (state.isFocused ? 'var(--primary-bg-color)' : 'var(--dark-color)'),
        }
    }),
    menu: provided => ({...provided, zIndex: 9999}),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0.175rem 2.25rem 0.175rem 0.75rem'
    }),
    multiValue: (provided) => ({
        ...provided,
        padding: '2px 4px',
        backgroundColor: '#eee'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        fontSize: '14px'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        cursor: 'pointer'
    }),
    option: (provided, state) => ({
        ...provided,
        fontSize: '14px',
        backgroundColor: state.isSelected ? '#e9ecef' : '#fff',
        color: state.isSelected ? 'rgba(98, 89, 202, 0.8)' : '#666666',

        '&:hover': {
            backgroundColor: 'rgba(98, 89, 202, 0.16)',
            color:'rgba(98, 89, 202, 0.8)'
        }
    }),
};


const MultiSelect = ({onChange, options: passedOptions, changeCallback, isDisabled, value: passedValue, ...props}) => {
    const {t} = useTranslation();
    const [value, setValue] = useState();
    const [options, setOptions] = useState();
    const {isMulti} = props;

    const handleChange = (data) => {
        if (isMulti) {
            let transformedData = data && data.length ? data.map(item => item.value) : null;
            onChange(transformedData);

            if (changeCallback) {
                changeCallback(transformedData);
            }
        } else {
            onChange(data?.value ? data?.value : null);

            if (changeCallback) {
                changeCallback(data?.value ? data?.value : null);
            }
        }
    }

    useEffect(() => {
        if (passedOptions) {
            let newOptions = passedOptions.map(item => {
                return {label: item.label, value: item.value}
            });
            if(!newOptions[0].label){
                newOptions = passedOptions.map(item => {
                    return {label: item.number, value: item.id}
                });
            }
            setOptions(newOptions);
        }

        if (passedValue) {
            if (isMulti) {
                setValue(passedOptions ? passedOptions.filter(item => passedValue.includes(item.value)) : '');
            } else {
                setValue(passedOptions ? passedOptions.find(item => item.value === passedValue) : '')
            }
        } else {
            setValue(null)
        }
    }, [passedValue, isMulti, passedOptions]);

    return (
        <Select isClearable options={options} value={value ? value : ''}
                placeholder={props.placeholder ? props.placeholder : t('labels.select')} isDisabled={isDisabled}
                onChange={handleChange} {...props} noOptionsMessage={() => t('labels.no_options')}
                loadingMessage={() => t('labels.loading')} styles={styles}/>
    );
};

export default MultiSelect;