import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

const Checkbox = ({checked, setChecked}) => {
    const {t} = useTranslation();

  return (
    <div className="form-group">
        <label className={checked ? 'ckeckbox-label ckeckbox-label-active' : 'ckeckbox-label'} onClick={(e) => setChecked(!checked)}>
            <div className='checked'/>
            <span>{t('labels.published')}</span>
        </label>
    </div>         
  )
}

export default Checkbox