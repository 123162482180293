import React, { useState } from 'react'
import { useEffect } from 'react'
import ImageUpload from '../../services/admin/imageUpload'

const FilesUploader = ({imgLinkList, setImgLinkList, setError}) => {
    const [imgList, setImgList] = useState([])

    useEffect(() => {
        if(imgLinkList){
            setImgList(imgLinkList)
        }
    }, [imgLinkList])

    const setImg = async (e) => {
        const currentFile = e.target.files[0];

        const res = await ImageUpload.upload(currentFile); 
        if(res.statusCode === 200) {
            setImgLinkList([...imgLinkList, res.content.url])
        }

        const url = URL.createObjectURL(currentFile)
        setImgList([...imgList, url])
    }

    const deleteImg = (value) => {
        setImgList(imgList.filter(item => item !== value))
        setImgLinkList(imgList.filter(item => item !== value))
    }


  return (
    <>
        <div className='fileuploader-flex mb-3'>
            {imgList &&
                imgList.map((item, index) =>
                    <div className='fileuploader-div' key={index}>
                        <img src={item} alt=''/> 
                        <i className='fe fe-trash-2' onClick={() => deleteImg(item)}></i>
                    </div>     
                ) 
            }
        </div>
        <div className='fileUpload fileUpload-fullwidth'>
            <input type="file"  accept="image/jpeg, image/png, text/html, application/zip, text/css, text/js" title='Выбрать фото'
                onChange={e => setImg(e)}
            />
        </div>
    </>
  )
}

export default FilesUploader
