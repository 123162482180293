import React, { useState } from 'react'
import FormGroup from '../../../components/simple/FormGroup'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Utils from '../../../services/utils';

const Filter = ({setFilterData, params}) => {
    const {t} = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    const {handleSubmit, register, reset, setValue, control, formState: {errors}} = useForm();

    const onSubmit = (data) => {
        setFilterData(Utils.objectToSearchParams(data))
    };

    
    const open = () => {
        setIsOpened(!isOpened)
    }

    const handleReset = (e) => {
        e.preventDefault();
        reset({phone: null});
    }

  return (
    <div className='card custom-card card-body'>
        <h4 className='filter-title' onClick={open}>
            Фильтр
            <i className={isOpened ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i>
        </h4>
        {isOpened &&
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup label={t('labels.phone')}>
                <input {...register('phone')} defaultValue={params.get('phone')} type="text" className="form-control2" placeholder={t('labels.phone')}/>
            </FormGroup>
            <div className='row justify-content-end'>
                <button type="submit" className="btn btn-primary my-2 btn-icon-tex me-2">
                    {t('labels.apply')}
                </button>
                <button onClick={handleReset} className="btn btn-light my-2 btn-icon-tex me-2">
                    {t('labels.reset')}
                </button>
            </div>
        </form>
        }
    </div>
  )
}

export default Filter