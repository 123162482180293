import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import Pagination from '../../components/complex/Pagination'
import Table from '../../components/complex/Table'
import useFetchItems from '../../hooks/useFetchItems'
import {adminRoutes} from '../../navigation/Constants'
import UserServices from '../../services/admin/UserServices'
import Filter from './components/Filter'

const Clients = () => {
	const {t} = useTranslation();

    const { 
        items, 
        loading, 
        pageCount, 
        params, 
        handlePageClick, 
		fetchItems,
        setFilterData 
    } = useFetchItems(UserServices.index, `?role=buyer`, true);

	const [formattedItems, setFormattedItems] = useState();


	useEffect(() => {
        if (items) {
            const newItems = items.items.map(({id, balance, subscription_until, last_activity, phone}) => {
                return {
                    id,
                    fields: [
                        id,
						phone,
						balance,
						subscription_until,
                        last_activity
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.phone'), t('labels.balance'), t('labels.subscription_until'), t('labels.last_activity'), ''],
        sizes: ['10"%', '15%', '15%', '20%', '20%', '']
    }


  return (
	<div className="main-container container-fluid">
		<div className="inner-body">
			<div className="page-header">
				<div>
					<h2 className="main-content-title tx-24 mg-b-5">{t('sidemenu.clients')}</h2>
					<ol className="breadcrumb">
						<li className="breadcrumb-item active" aria-current="page">{t('sidemenu.users')}</li>
					</ol>
				</div>
				<div className="d-flex">
					<div className="justify-content-center">
						<NavLink to={adminRoutes.users.create}>
							<button type="button" className="btn btn-primary my-2 btn-icon-text">
								{t('labels.create')}
							</button>
						</NavLink>
					</div>
				</div>
			</div>
			<Filter setFilterData={setFilterData} params={params}/>
			<div className="row row-sm">
				<div className="col-md-12 col-lg-12">
					<div className="card custom-card">
						<div className="card-header  border-bottom-0 pb-0">
							<div>
								<div className="d-flex">
									<label className="main-content-label my-auto pt-2">{t('titles.all_users')}</label>
								</div>
							</div>
						</div>
						<div className="card-body">
							{formattedItems ?
								<>
									<Table 
										{...{config: tableConfig, fetchItems, loading}}
										items={formattedItems}
										removeMethod={UserServices.delete}
										replenishBalanceMethod={UserServices.replenishBalance}
										renewSubscriptionMethod={UserServices.renewSubscription}
									/>
									<Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
								</>
								: <div></div>
							}
						</div>
					</div>
				</div>
				<div className="col-md-12 col-lg-12">
					<div className="card custom-card">
						<div className="card-body">
                            <div className="card-stat">
                                {items?.stat?.map((elem, index) =>
                                    <div key={index}>{elem.name}: <b>Всего {elem.count}</b></div>
                                )}
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Clients