import Adverts from "../pages/adverts"
import AdvertEdit from "../pages/adverts/AdvertEdit"
import ForgotPassword from "../pages/login/ForgotPassword"
import LogIn from "../pages/login/LogIn"
import LogUp from "../pages/login/LogUp"
import Profile from "../pages/login/Profile"
import ResetPassword from "../pages/login/ResetPassword"
import Transactions from "../pages/transactions"
import Users from "../pages/users"
import Clients from "../pages/users/Clients"
import UserCreate from "../pages/users/UserCreate"
import UserEdit from "../pages/users/UserEdit"
import { adminRoutes, FORGOT_PASSWORD_PAGE_ROUTE, LOGIN_PAGE_ROUTE, LOGUP_PAGE_ROUTE, MAIN_PAGE_ROUTE, RESET_PASSWORD_PAGE_ROUTE } from "./Constants"


export const publicRoutes = [
    {
        path: LOGIN_PAGE_ROUTE,
        Component: LogIn
    },
    {
        path: LOGUP_PAGE_ROUTE,
        Component: LogUp
    },
    {
        path: MAIN_PAGE_ROUTE,
        Component: LogIn
    },
    {
        path: FORGOT_PASSWORD_PAGE_ROUTE,
        Component: ForgotPassword
    },
    {
        path: RESET_PASSWORD_PAGE_ROUTE,
        Component: ResetPassword
    }
]

export const privateRoutes = [
    {
        path: MAIN_PAGE_ROUTE,
        Component: Users
    },
    {
        path: adminRoutes.users.index,
        Component: Users
    },
    {
        path: adminRoutes.users.seller.index,
        Component: Users
    },
    {
        path: adminRoutes.users.client.index,
        Component: Clients
    },
    {
        path: adminRoutes.users.create,
        Component: UserCreate
    },
    {
        path: adminRoutes.users.edit.index(),
        Component: UserEdit
    },
    {
        path: adminRoutes.adverts.index,
        Component: Adverts
    },
    {
        path: adminRoutes.adverts.edit.index(),
        Component: AdvertEdit
    },
    {
        path: adminRoutes.transactions.index,
        Component: Transactions
    },
    {
        path: adminRoutes.profile.index,
        Component: Profile
    }
]