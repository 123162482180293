import React, { useState } from 'react';
import { toast } from 'react-toastify';

const Switch = ({is_approved, id, onChecked, switchMethod, ...props}) => {
    const [checked, setChecked] = useState(is_approved);

    const [loading, setLoading] = useState()

    const handleClick = async () => {
        if (loading){
            return
        }
            setLoading(true);
            setChecked(!checked)

            const res = await switchMethod(id);

            if(res.statusCode === 200){
            }else{
                toast.error(res.message);
                setChecked(false)
            }
        setLoading(false)
    }

    return (
        <div onClick={handleClick}>
            <div className={checked ? 'switch-input checked' : 'switch-input'}/>
            <span/>
        </div>
    );
};

export default Switch;