import React from 'react'
import ProfileContainer from '../../components/simple/ProfileContainer'

const Profile = () => {
  return (
    <div>
      <ProfileContainer/>
    </div>
  )
}

export default Profile