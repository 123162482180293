import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import Header from '../../components/complex/Header'
import Pagination from '../../components/complex/Pagination'
import Sidemenu from '../../components/complex/Sidemenu'
import Table from '../../components/complex/Table'
import PageHeader from '../../components/simple/PageHeader'
import useFetchItems from '../../hooks/useFetchItems'
import {adminRoutes} from '../../navigation/Constants'
import TransactionsServices from '../../services/admin/TransactionsServices'
import UserServices from '../../services/admin/UserServices'

const Transactions = () => {
	const {t} = useTranslation();

    const { 
        items, 
        loading, 
        pageCount, 
        params, 
        handlePageClick, 
		fetchItems,
        setFilterData 
    } = useFetchItems(TransactionsServices.index);

	const [formattedItems, setFormattedItems] = useState();

	useEffect(() => {
        if (items) {
            const newItems = items.map(({id, cost, action, user, creator}) => {
                return {
                    id,
                    fields: [
                        id,
						user.phone,
						action,
                        cost,
						creator &&<>
							{creator.name ? 
								creator.name
							:
								creator.phone
							}
						</>
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.user_phone'), t('labels.action'), t('labels.cost'), t('labels.creator')],
        sizes: ['6%', '20%', '34%', '15%', '20%']
    }

  return (
	<div className="main-container container-fluid">
		<div className="inner-body">
			<div className="page-header">
				<div>
					<h2 className="main-content-title tx-24 mg-b-5">{t('sidemenu.transactions')}</h2>
					<ol className="breadcrumb">
						<li className="breadcrumb-item active" aria-current="page">{t('sidemenu.transactions')}</li>
					</ol>
				</div>
			</div>
			<div className="row row-sm">
				<div className="col-md-12 col-lg-12">
					<div className="card custom-card">
						<div className="card-header  border-bottom-0 pb-0">
							<div>
								<div className="d-flex">
									<label className="main-content-label my-auto pt-2">{t('titles.all_transactions')}</label>
								</div>
							</div>
						</div>
						<div className="card-body">
							{formattedItems ?
								<>
									<Table 
										{...{config: tableConfig, fetchItems, loading}}
										items={formattedItems}
									/>
									<Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
								</>
								: <div></div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Transactions