import React, { Suspense } from 'react'
import AppRouter from './navigation/AppRouter';
import "./App.scss"
import { BrowserRouter } from 'react-router-dom'
import './i18n/i18n'
import ProvideLang from "./i18n/ProvideLang";
import { ProvideAuth } from './pages/login/ProvideAuth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <Suspense fallback={null}>
      <ProvideLang>
          <ProvideAuth>
            <BrowserRouter>
              <AppRouter/>
              <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
            </BrowserRouter>
          </ProvideAuth>
        </ProvideLang>
    </Suspense>
  )
}

export default App