import React, { useEffect, useState } from "react";
import useFetchInfo from "../../../hooks/useFetchInfo.hook";
import AdvertsServices from "../../../services/admin/AdvertsServices";
import { useTranslation } from "react-i18next";
import useFetchItems from "../../../hooks/useFetchItems";
import Table from "../../../components/complex/Table";
import Pagination from "../../../components/complex/Pagination";
import FormGroup from "../../../components/simple/FormGroup";

const ViewLog = ({ id }) => {
  const { t } = useTranslation();

  const [formattedItems, setFormattedItems] = useState();

  const {
    items,
    loading,
    pageCount,
    params,
    handlePageClick,
    fetchItems,
    setFilterData,
  } = useFetchItems(AdvertsServices.viewsLog, id);

  useEffect(() => {
    if (items) {
      const newItems = items.map(({ id, user, created_at }) => {
        return {
          id,
          fields: [user?.phone, created_at],
        };
      });

      setFormattedItems(newItems);
    }
  }, [items]);

  const tableConfig = {
    head: [t("labels.client"), t("labels.data")],
    sizes: ["50%", "50%"],
  };

  return (
    formattedItems?.length >= 1 && (
        <div className="col-md-12 col-lg-12">
            <div className="card custom-card">
                <div className="card-header  border-bottom-0 pb-0">
                    <div>
                    <div className="d-flex">
                        <label className="main-content-label my-auto pt-2">
                        {t("labels.static_views")}
                        </label>
                    </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-horizontal">
                    <FormGroup>
                        <Table
                        {...{ config: tableConfig, fetchItems, loading }}
                        items={formattedItems}
                        />
                        <Pagination
                        {...{ loading, pageCount, params }}
                        handlePageClick={handlePageClick}
                        />
                    </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    )
  );
};

export default ViewLog;
