import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class AdvertsServices {
    static index = (queryString) => {
        return getData(http, `/adverts${queryString ? `?${queryString}` : ""}`);
    }

    static view = (id, queryString) => {
        return getData(http, `/adverts/${id}${queryString ? `${queryString}` : ""}`);
    }

    static viewsLog = (id) => {
        return getData(http, `/adverts/${id}/viewsLog`);
    }

    static update = (id, data) => {
        return postData(http, `/adverts/${id}`, data);
    }

    static delete = (id) => {
        return deleteData(http, `/adverts/${id}`);
    }

    static stat = (queryString) => {
        return getData(http, `/adverts/stat${queryString ? `?${queryString}` : ""}`);
    }
}