import React, {useState} from 'react';
import { NavLink, useLocation, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import AuthServices from "../../services/admin/AuthServices";
import img1 from "../../img/brand/logo-light.png"
import img2 from "../../img/brand/logo.png"
import img3 from "../../img/svgs/user.svg"
import img4 from "../../img/brand/logo.png"
import {toast} from "react-toastify";
import FormGroup from '../../components/simple/FormGroup';
import { FORGOT_PASSWORD_PAGE_ROUTE } from '../../navigation/Constants';

const ResetPassword = () => {
    const {handleSubmit, register} = useForm();
    const history = useNavigate();
    const [loading, setLoading] = useState();
    const {t} = useTranslation();

    const search = useLocation().search;
    const email = new URLSearchParams(search).get('email');

    const onSubmit = async (data) => {
        const newData = {
            email: email,
            ...data
        }

        setLoading(true);
        const res = await AuthServices.checkCode(newData);
        setLoading(false);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            history.push('')
        }
    }

    return (
        <div className="ltr main-body leftmenu error-1">
    	<div className="page main-signin-wrapper">
			<div className="row signpages text-center">
				<div className="col-md-12">
					<div className="card">
						<div className="row row-sm">
							<div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
								<div className="mt-5 pt-4 p-2">
									<img src={img1} className="d-lg-none header-brand-img text-start float-start mb-4 error-logo-light" alt="logo"/>
									<img src={img2} className=" d-lg-none header-brand-img text-start float-start mb-4 error-logo" alt="logo"/>
									<img src={img3} className="ht-100 my-auto position-relative" alt="user"/>
								</div>
							</div>
							<div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
								<div className="main-container container-fluid">
									<div className="row row-sm">
										<div className="card-body mt-2 mb-2">
											<img src={img4} className=" d-lg-none header-brand-img text-start float-start mb-4" alt="logo"/>
											<div className="clearfix"></div>
                                            <form onSubmit={handleSubmit(onSubmit)} className={loading ? 'isLoading' : ''}>
                                                <FormGroup label={t('labels.enter_code')}>
                                                    <input className="form-control2" type="text" {...register("code")} required/>
                                                </FormGroup>
                                                <div className="mb-10">
                                                    <button>{t('labels.recover_password')}</button>
                                                </div>
                                                <div className="text-center">
                                                    <NavLink to={''}>{t('labels.cancel')}</NavLink>
                                                </div>
                                            </form>
											<div className="text-start mt-5 ms-0">
												<div className="mb-1"><NavLink to={FORGOT_PASSWORD_PAGE_ROUTE}>{t('labels.forgot_password')}</NavLink></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    );
};

export default ResetPassword;