import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormGroup from '../../components/simple/FormGroup';
import MultiSelect from '../../components/ui/MultiSelect';
import useStore from '../../hooks/useStore.hook';
import { adminRoutes } from '../../navigation/Constants';
import Dictionaries from '../../services/admin/dictionaries';
import UserServices from '../../services/admin/UserServices';

const UserCreate = () => {
  const {t} = useTranslation();

  const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

  const {onSubmit, loading} = useStore(UserServices.store, null, null, adminRoutes.users.index);
  
  const [loadingRoles, setLoadingRoles] = useState();
  const [roles, setRoles] = useState();

  const fetchRoles = useCallback(async () => {
    setLoadingRoles(true);

    try {
        const res = await Dictionaries.roles();

        setRoles(res.content);
    } catch (e) {
        toast.error(e.message)
    }

    setLoadingRoles(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return (
    <div className="main-container container-fluid" >
      <div className="inner-body">
        <div className="page-header">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">{t('sidemenu.users')}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to={adminRoutes.users.index}>{t('titles.all_users')}</NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">{t('labels.creating')}</li>
            </ol>
          </div>
        </div>
        <div className="row row-sm">
          <div className="col-md-12 col-lg-12">
            <div className="card custom-card">
              <div className="card-header  border-bottom-0 pb-0">
                <div>
                  <div className="d-flex">
                    <label className="main-content-label my-auto pt-2">{t('labels.create_user')}</label>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup label={t('labels.phone')}>
                      <input {...register('phone')} required type="tel" className={errors.phone ? "form-control2 error" : "form-control2"} placeholder={t('labels.phone')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.roles')}>
                        <Controller name="roles" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <MultiSelect isMulti $small options={roles} onChange={onChange}
                                                        value={value}
                                                        isLoading={loadingRoles}
                                                        isDisabled={loadingRoles}
                                            />
                                    )}
                        />
                    </FormGroup>
                    <div className='row justify-content-end'>
                      <button type="submit" className="btn btn-primary my-2 btn-icon-tex me-2">
                         {t('labels.save')}
                      </button>
                    </div>
                </form>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserCreate