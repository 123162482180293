import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom'
import RenewSubscriptionModal from '../../pages/users/RenewSubscriptionModal';
import ReplenishBalanceModal from '../../pages/users/ReplenishBalanceModal';
import ConfirmActionModal from './ConfirmActionModal';
import Switch from '../ui/Switch';

const Table = ({
                config,
                items,
                fetchItems,
                editLink,
                removeMethod,
                renewSubscriptionMethod,
                replenishBalanceMethod,
                subDelete,
                switchMethod,
                ...props
            }) => {
    const {t} = useTranslation();
    const {head, sizes} = config;
    const navigate = useNavigate();
    const {removeModalContent} = props;

    const {id} = useParams();

    const [replenishBalanceModal, setReplenishBalanceModal] = useState();
    const [renewSubscriptionModal, setRenewSubscriptionModal] = useState();

    const [removeId, setRemoveId] = useState();
    const [removeModalIsOpen, setRemoveModalIsOpen] = useState(false);

    const [replenishId, setReplenishId] = useState()
    const [renewId, setRenewId] = useState()

    const handleEdit = (e, itemId) => {
        e.stopPropagation();
        if(subDelete && editLink){
            navigate(editLink(id, itemId))
        }
        else if (editLink) {
            navigate(editLink(itemId))
        }
    }

    const handleRemove = (e, id) => {
        e.stopPropagation();
        setRemoveId(id);
        setRemoveModalIsOpen(true)
    }

    const handleBalance = (e, id) => {
        e.stopPropagation();
        setReplenishId(id)
        setReplenishBalanceModal(true)
    }

    const handleRenew = (e, id) => {
        e.stopPropagation();
        setRenewId(id)
        setRenewSubscriptionModal(true)
    }

  return (
    <div className="table-responsive border border-bottom-0">
        <table className="table mb-0">
            <thead>
                <tr className="border-bottom">
                    {head.map((item, index) => (
                        <th key={index} style={{width: sizes[index]}}>{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {items.map(({id, is_approved, fields}) => 
                    <tr className="border-bottom" key={id} >
                        {fields.map((td, index) => (
                           <td key={index}>{td}</td>
                        ))}
                        {switchMethod &&
                            <td>
                                <Switch id={id} is_approved={is_approved} switchMethod={switchMethod}/>
                            </td>
                        }
                        {(editLink || removeMethod ) &&
                        <td style={{textAlign: 'right'}}>
                            <div className="button-list">
                                {replenishBalanceMethod && 
                                    <div className="btn" onClick={e => handleBalance(e, id)}><i className="fa fa-money"></i></div>
                                }
                                {renewSubscriptionMethod && 
                                    <div className="btn" onClick={e => handleRenew(e, id)}><i className="fa fa-check-circle"></i></div>
                                }
                                {editLink &&
                                    <div className="btn" onClick={e => handleEdit(e, id)}><i className="ti ti-pencil"></i></div>
                                }
                                {removeMethod && 
                                    <div className="btn" onClick={e => handleRemove(e, id)}><i className="ti ti-trash"></i></div>
                                }
                            </div>
                        </td>
                        }
                    </tr>
                )}
            </tbody>
        </table>
        {removeMethod &&
                <ConfirmActionModal
                    isOpen={removeModalIsOpen}
                    closeModal={() => setRemoveModalIsOpen(false)}
                    onRequestClose={() => setRemoveModalIsOpen(false)}
                    successCallback={() => {
                        setRemoveModalIsOpen(false);
                        fetchItems();
                    }}
                    method={removeMethod}
                    data={removeId}
                    content={removeModalContent ? removeModalContent : {
                        title: t('messages.remove_message'),
                        yes: t('labels.yes'),
                        no: t('labels.cancel')
                    }}
                    subId={id}
                />
        }
        {replenishBalanceMethod && 
            <ReplenishBalanceModal
				isOpen={replenishBalanceModal}
				closeModal={() => setReplenishBalanceModal(false)}
				onRequestClose={() => setReplenishBalanceModal(false)}
				successCallback={() => {
					setReplenishBalanceModal(false);
                    fetchItems();
				}}
                id={replenishId}
			/>
        }
        {renewSubscriptionMethod && 
            <RenewSubscriptionModal
				isOpen={renewSubscriptionModal}
				closeModal={() => setRenewSubscriptionModal(false)}
				onRequestClose={() => setRenewSubscriptionModal(false)}
				successCallback={() => {
					setRenewSubscriptionModal(false);
                    fetchItems();
				}}
                id={renewId}
			/>
        }
    </div>
  )
}

export default Table