import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

const useStore = (method, additionalData, id=null, path = null) => {
    const [loading, setLoading] = useState();
    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setLoading(true)
        const res = additionalData ? await method(data, additionalData) : id ? await method(id, data) : await method(data);

        setLoading(false);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)
 
        if (res.statusCode === 200) {
            if(path){
                navigate(path)
            }
        }
    }

    return {
        onSubmit,
        loading
    };
};

export default useStore;