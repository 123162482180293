import React from 'react'
import img1 from "../../img/logo-white.png"
import img2 from "../../img/brand/icon-light.png"
import img3 from "../../img/logo.png"
import img4 from "../../img/brand/icon.png"
import { NavLink, useLocation } from 'react-router-dom'
import MenuItem from '../simple/MenuItem'
import { adminRoutes, MAIN_PAGE_ROUTE } from '../../navigation/Constants'


const Sidemenu = () => {

	const handlerEnter = () => {
		let main_body = document.getElementById('main-body');
		if(main_body.classList.contains('main-sidebar-hide')){
			main_body.classList.add('main-sidebar-open')
		}
	}

	const handlerLeave = () => {
		let main_body = document.getElementById('main-body');
		if(main_body.classList.contains('main-sidebar-hide')){
			main_body.classList.remove('main-sidebar-open')
		}
	}

	const menuItems = [
		{id: 1, slug: 'users', title: 'Пользователи', link: adminRoutes.users.index, icon: 'mdi mdi-account-multiple-plus', elements: [
            {id: 1, title: 'Продавцы', link: adminRoutes.users.seller.index, elements: null},
            {id: 2, title: 'Клиенты', link: adminRoutes.users.client.index, elements: null}
        ]},
		{id: 2, slug: 'adverts', title: 'Объявления', elements: null, link: adminRoutes.adverts.index, icon: 'ti-layers'},
		{id: 3, slug: 'transactions', title: 'Транзакции', elements: null, link: adminRoutes.transactions.index, icon: 'fa fa-calendar-check-o'},
	]

  return (
    <div className="sticky">
			<div className="main-menu main-sidebar main-sidebar-sticky side-menu" onMouseEnter={() => handlerEnter()} onMouseLeave={() => handlerLeave()}>
				<div className="main-sidebar-header main-container-1 active">
					<div className="sidemenu-logo">
						<NavLink className="main-logo" to={MAIN_PAGE_ROUTE}>
							<img src={img1} className="header-brand-img desktop-logo" alt="logo"/>
							<img src={img2} className="header-brand-img icon-logo" alt="logo"/>
							<img src={img3} className="header-brand-img desktop-logo theme-logo" alt="logo"/>
							<img src={img4} className="header-brand-img icon-logo theme-logo" alt="logo"/>
						</NavLink>
					</div>
					<div className="main-sidebar-body main-body-1">
						<div className="slide-left disabled" id="slide-left"><i className="fe fe-chevron-left"></i></div>
						<ul className="menu-nav nav">
							{
								menuItems.map((item, index) => 
									<div key={index}>
										<MenuItem item={item}/>
									</div>
								)
							}
						</ul>
						<div className="slide-right" id="slide-right"><i className="fe fe-chevron-right"></i></div>
					</div>
				</div>
			</div>
		</div>
  )
}

export default Sidemenu