import React from 'react'
import img1 from "../../img/brand/logo-light.png"
import img2 from "../../img/brand/logo.png"
import img3 from "../../img/svgs/user.svg"
import img4 from "../../img/brand/logo.png"
import { useForm } from 'react-hook-form'
import { useAuth } from './ProvideAuth'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import AuthServices from '../../services/admin/AuthServices'
import { FORGOT_PASSWORD_PAGE_ROUTE, MAIN_PAGE_ROUTE } from '../../navigation/Constants'

const LogIn = () => {
	const {handleSubmit, register} = useForm();
	const {setToken, setInfo} = useAuth();
	const navigate = useNavigate();

	const {t} = useTranslation();
	
	const onSubmit = async (data) => {

        const res = await AuthServices.logIn(data);

        if (res.statusCode === 200) {
            setInfo(res.content);
            setToken(res.content.token);
			navigate(MAIN_PAGE_ROUTE);
        }else{
			toast.error(res.message)
		}
    }

  return (
	<div className="ltr main-body leftmenu error-1">
    	<div className="page main-signin-wrapper">
			<div className="row signpages text-center">
				<div className="col-md-12">
					<div className="card">
						<div className="row row-sm">
							<div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
								<div className="mt-5 pt-4 p-2">
									<img src={img1} className="d-lg-none header-brand-img text-start float-start mb-4 error-logo-light" alt="logo"/>
									<img src={img2} className=" d-lg-none header-brand-img text-start float-start mb-4 error-logo" alt="logo"/>
									<img src={img3} className="ht-100 my-auto position-relative" alt="user"/>
								</div>
							</div>
							<div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
								<div className="main-container container-fluid">
									<div className="row row-sm">
										<div className="card-body mt-2 mb-2">
											<img src={img4} className=" d-lg-none header-brand-img text-start float-start mb-4" alt="logo"/>
											<div className="clearfix"></div>
											<form onSubmit={handleSubmit(onSubmit)}>
												<h5 className="text-start mb-3">{t('labels.login')}</h5>
												<div className="form-group text-start">
													<label>{t('labels.email')}</label>
													<input className="form-control2" placeholder="Введите ваш E-mail" type="email" {...register("email")} required/>
												</div>
												<div className="form-group text-start">
													<label>{t('labels.password')}</label>
													<input className="form-control2" placeholder="Введите ваш пароль" type="password" {...register("password")} required/>
												</div>
												<button className="btn ripple btn-main-primary btn-block">{t('labels.sigin')}</button>
											</form>
											{/*<div className="text-start mt-5 ms-0">
												<div className="mb-1"><NavLink to={FORGOT_PASSWORD_PAGE_ROUTE}>{t('labels.forgot_password')}</NavLink></div>
  </div>*/}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default LogIn