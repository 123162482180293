import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import FormGroup from '../../components/simple/FormGroup';
import UserServices from '../../services/admin/UserServices';
import useFetchInfo from "../../hooks/useFetchInfo.hook";
import useUpdate from '../../hooks/useUpdate.hook';
import { adminRoutes } from '../../navigation/Constants';
import ConfirmActionModal from '../../components/complex/ConfirmActionModal';
import MultiSelect from '../../components/ui/MultiSelect';
import Dictionaries from '../../services/admin/dictionaries';
import { toast } from 'react-toastify';
import AdvertsServices from '../../services/admin/AdvertsServices';
import Checkbox from '../../components/ui/Checkbox';
import FilesUploader from '../../components/complex/FilesUploader';
import ViewLog from './components/ViewLog';

const AdvertEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {info, loading: fetchLoading} = useFetchInfo(AdvertsServices.view, id);

    const [checked, setChecked] = useState(false)
    const [imgList, setImgList] = useState([]);

    const {onSubmit, loading} = useUpdate(AdvertsServices.update, id);

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();

    useEffect(() => {
        if (info) {
          const {images, creator, is_published, ...rest} = info
          for (const [key, value] of Object.entries(rest)) {
            rest[key] !== null && setValue(key, String(value));
          }
          images && setImgList(images)
          creator && setValue('creator_phone', creator.phone)
          is_published && setChecked(is_published)
        }
    }, [info, setValue])

    const CustomSubmit = (data) => {
        const {images, ...rest} = data
        const newData = {
            ...rest,
            images: imgList,
            is_published: checked
        }
        onSubmit(newData)
    }

    const handleRemove = (e) => {
      e.preventDefault();
      setRemoveModalIsOpen(true);
    }

  return (
    <div className="main-container container-fluid">
      <div className="inner-body">
        <div className="page-header">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">{t('sidemenu.adverts')}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to={adminRoutes.adverts.index}>{t('titles.all_adverts')}</NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">{t('labels.edit')}</li>
            </ol>
          </div>
        </div>
        <div className="row row-sm">
          <div className="col-md-12 col-lg-12">
            <div className="card custom-card">
              <div className="card-header  border-bottom-0 pb-0">
                <div>
                  <div className="d-flex">
                    <label className="main-content-label my-auto pt-2">{t('labels.edit_advert')}</label>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form className="form-horizontal" onSubmit={handleSubmit(CustomSubmit)}>
                    <FormGroup label={t('labels.city')}>
                      <input {...register('city')} type="text" className={"form-control2"} placeholder={t('labels.city')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.description')}>
                      <textarea {...register('description')} type="email" className={"form-control2"} placeholder={t('labels.email')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.creator_phone')}>
                      <input {...register('creator_phone')} type="tel" disabled className={"form-control2"} placeholder={t('labels.creator_phone')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.images')}>
                        <Controller defaultValue={[]} name="images" control={control}
                                    render={({field: {onChange, value}}) => (
                                    <FilesUploader imgLinkList={imgList} setImgLinkList={setImgList}/>
                        )}/>
                    </FormGroup>
                    <Checkbox checked={checked} setChecked={setChecked}/>
                    <div className='row justify-content-end'>
                      <button type="button" className="btn my-2 btn-outline-primary me-2" onClick={handleRemove}>
                        <i className="ti-trash me-2"></i> {t('labels.delete')}
                      </button>
                      <button type="submit" className="btn btn-primary my-2 btn-icon-tex me-2">
                         {t('labels.save')}
                      </button>
                    </div>
                </form>                
              </div>
            </div>
          </div>
            <ViewLog id={id}/>
        </div>
      </div>
      <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    navigate(adminRoutes.users.index)
                }}
                method={AdvertsServices.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
    </div>
  )
}

export default AdvertEdit