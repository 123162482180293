import React, { useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const MenuItem = ({item, ...props}) => {
  const location = useLocation();
  const [current, setCurrent] = useState();

  return (
      <li {...props} className={location.pathname.startsWith(item.link) ? "nav-item show active" : "nav-item"}>
        <NavLink to={item.link} className="nav-link with-sub">
          <span className="shape1"></span>
          <span className="shape2"></span>
          <i className={item.icon ? "sidemenu-icon menu-icon "+item.icon : "ti-palette sidemenu-icon menu-icon"}></i>
          <span className="sidemenu-label">{item.title}</span>
          {item.elements && <i className="angle fe fe-chevron-right"></i> }
        </NavLink>
          {item.elements &&
            <ul className={location.pathname.startsWith(item.link) ? "nav-sub open" : "nav-sub"}>
                  {item.elements.map((elem, index) => 
                      <li className={location.pathname.startsWith(item.link) ? "nav-sub-item active" : "nav-sub-item"} key={index}>
                          <NavLink to={elem.link} className="nav-sub-link">{elem.title}</NavLink>
                      </li>
                  )}
              </ul>
          }
      </li>
  )
}

export default MenuItem