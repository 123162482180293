import React from 'react'

const FormGroup = ({label, children}) => {
  return (
    <div className="form-group">
        <div className="row row-sm">
            <div className='col-md-12'>
                <label className="form-label">{label}</label>
                {children}
            </div>
        </div>
    </div>
  )
}

export default FormGroup