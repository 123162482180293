import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick.hook";
import img1 from "../../img/users/1.jpg"
import { PROFILE_PAGE_ROUTE } from "../../navigation/Constants";
import { useAuth } from "../../pages/login/ProvideAuth";
import AuthServices from "../../services/admin/AuthServices";

const ProfileDropdown = () => {
    const dropdownRef = useRef();
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const {removeToken, removeInfo, userData} = useAuth();
    
    const logOut = async () => {
        const res = await AuthServices.logOut();

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            removeToken();
            removeInfo();
        }
    }

  return (
    <div className={isActive ? "dropdown main-profile-menu show" : "dropdown main-profile-menu"} ref={dropdownRef}>
        <div className="d-flex" onClick={() => setIsActive(!isActive)}>
            <span className="main-img-user">
                <img alt="avatar" src={img1} />
            </span>
        </div>
        <div className="dropdown-menu">
            <div className="header-navheading">
                <h6 className="main-notification-title">{userData?.name}</h6>
                <p className="main-notification-text">{userData?.email}</p>
                <p className="main-notification-text">{userData?.roles[0]}</p>
            </div>
            <NavLink to={PROFILE_PAGE_ROUTE} className="dropdown-item border-top">
                <i className='fe fe-edit'></i> Профиль
            </NavLink>
            <div className="dropdown-item border-top" onClick={logOut}>
                <i className='fe fe-power'></i> Выйти
            </div>
        </div>
    </div>
  );
};

export default ProfileDropdown;
