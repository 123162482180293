import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ModalComponent from '../../components/complex/ModalComponent';
import FormGroup from '../../components/simple/FormGroup';
import Dictionaries from '../../services/admin/dictionaries';
import UserServices from '../../services/admin/UserServices';
import CustomSelect from '../../components/ui/CustomSelect';

const RenewSubscriptionModal = ({closeModal, successCallback, id, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    const {handleSubmit, register, getValues, reset, control, formState: {errors}} = useForm();

    const [subscriptionTypes, setSubscriptionTypes] = useState()

    const [loadingTypes, setLoadingTypes] = useState()

      
    const fetchSubscriptionTypes = useCallback(async () => {
      setLoadingTypes(true);
  
      try {
          const res = await Dictionaries.subscriptionTypes();
  
          setSubscriptionTypes(res.content);
      } catch (e) {
          toast.error(e.message)
      }
  
      setLoadingTypes(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    useEffect(() => {
      fetchSubscriptionTypes();
    }, [fetchSubscriptionTypes]);

    const CustomSubmit = async (data) => {
        const res = await UserServices.renewSubscription(id, data)

        if(res.statusCode === 200){
          toast.success(res.message);
          successCallback();
        }else{
          toast.error(res.message)
        }
        reset();
        closeModal();
    }

      
  return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "600px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('labels.renew_subscription')}</h4>
                <form onSubmit={handleSubmit(CustomSubmit)}>
                    <FormGroup label={t('labels.renew_date') + '*'}>
                          <Controller name="subscription_type_id" control={control}
                                      render={({field: {onChange, value}}) => (
                                          <CustomSelect options={subscriptionTypes} onChange={onChange}
                                                        isLoading={loadingTypes}
                                                        value={value}
                                                        $error={errors.subscription_type_id}
                                          />
                                      )}
                                      rules={{required: true}}
                          />
                      </FormGroup>
                    <div className='row justify-content-end'>
                      <div className='col-auto'>
                        <button className="btn btn-primary" type='submit'>
                          {t('labels.renew_subscription')}
                        </button>
                      </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
  )
}

export default RenewSubscriptionModal