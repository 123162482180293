import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Pagination from '../../components/complex/Pagination'
import Table from '../../components/complex/Table'
import useFetchItems from '../../hooks/useFetchItems'
import {adminRoutes} from '../../navigation/Constants'
import AdvertsServices from '../../services/admin/AdvertsServices'
import AdvertStat from './components/AdvertStat'

const Adverts = () => {
	const {t} = useTranslation();

    const { 
        items, 
        loading, 
        pageCount, 
        params, 
        handlePageClick, 
		fetchItems,
        setFilterData 
    } = useFetchItems(AdvertsServices.index);

	const [formattedItems, setFormattedItems] = useState();

	useEffect(() => {
        if (items) {
            const newItems = items.map(({id, description, city, is_published, creator, views}) => {
                return {
                    id,
                    fields: [
                        id,
						description,
                        city,
						<>
							{is_published ? 
								<span className="status bg-success"></span>
								:
								<span className="status bg-danger"></span>
							}
						</>,
                        <>
                            {creator &&
                                creator.phone
                            }
                        </>,
                        views
                    ]
                }
            })

            setFormattedItems(newItems);
        }
    }, [items]);

    const tableConfig = {
        head: [t('labels.id'), t('labels.description'), t('labels.city'), t('labels.is_published'), t('labels.creator'), t('labels.views'), ''],
        sizes: ['6%', '35%', '12%', '10%', '15%', '10%', '']
    }

    
  return (
	<div className="main-container container-fluid">
		<div className="inner-body">
			<div className="page-header">
				<div>
					<h2 className="main-content-title tx-24 mg-b-5">{t('sidemenu.adverts')}</h2>
					<ol className="breadcrumb">
						<li className="breadcrumb-item active" aria-current="page">{t('sidemenu.adverts')}</li>
					</ol>
				</div>
			</div>
            <AdvertStat/>
			<div className="row row-sm">
				<div className="col-md-12 col-lg-12">
					<div className="card custom-card">
						<div className="card-header  border-bottom-0 pb-0">
							<div>
								<div className="d-flex">
									<label className="main-content-label my-auto pt-2">{t('titles.all_adverts')}</label>
								</div>
							</div>
						</div>
						<div className="card-body">
							{formattedItems ?
								<>
									<Table 
										{...{config: tableConfig, fetchItems, loading}}
										items={formattedItems}
										editLink={adminRoutes.adverts.edit.index}
										removeMethod={AdvertsServices.delete}
									/>
									<Pagination {...{loading, pageCount, params}} handlePageClick={handlePageClick}/>
								</>
								: <div></div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Adverts