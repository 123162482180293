import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import DefaultLayout from '../components/complex/DefaultLayout';
import { useAuth } from '../pages/login/ProvideAuth';
import { MAIN_PAGE_ROUTE } from './Constants';
import { privateRoutes, publicRoutes } from './Routes';

const AppRouter = () => {
  const {userData} = useAuth();
  const role = userData ? userData.roles[0] : null;
  const approvedRoles = ['admin', "company_operator", "laboratory_administrator", "esep_call_center_operator", "esep_admin", "analytic", "sealer_operator"]

  return (
    <Routes>
        {!role && publicRoutes.map(({path, Component}) => 
          <Route key={path} path={path} element={<Component></Component>} exact/>
        )}
        {role === 'admin' &&
          privateRoutes.map(({path, Component}) => 
            <Route key={path} path={path} element={
              <DefaultLayout>
                <Component/>
              </DefaultLayout>
            } exact/>
          )
        }
        <Route
            path="*"
            exact
            element={<Navigate to={MAIN_PAGE_ROUTE}/>}
        />
    </Routes>
  )
}

export default AppRouter