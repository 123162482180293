import React from 'react'
import Header from './Header'
import Sidemenu from './Sidemenu'

const DefaultLayout = ({children}) => {
  return (
    <div className="ltr main-body leftmenu" id="main-body">
        <div className="page">
			<Header/>
            <Sidemenu/>
            <div className="main-content side-content pt-0 mr-t-64">
                {children}
            </div>
        </div>
    </div>
  )
}

export default DefaultLayout