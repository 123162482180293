import React, {useState} from 'react';
import { NavLink, useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import AuthServices from "../../services/admin/AuthServices";
import img1 from "../../img/brand/logo-light.png"
import img2 from "../../img/brand/logo.png"
import img3 from "../../img/svgs/user.svg"
import img4 from "../../img/brand/logo.png"
import {toast} from "react-toastify";
import FormGroup from '../../components/simple/FormGroup';
import { FORGOT_PASSWORD_PAGE_ROUTE, LOGIN_PAGE_ROUTE, RESET_PASSWORD_PAGE_ROUTE } from '../../navigation/Constants';

const ForgotPassword = () => {
    const {handleSubmit, register} = useForm();
    const history = useNavigate();
    const [loading, setLoading] = useState();
    const {t} = useTranslation();

    const onSubmit = async (data) => {
        setLoading(true);
        const res = await AuthServices.sendCode(data);
        setLoading(false);

        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            history.push({pathname: RESET_PASSWORD_PAGE_ROUTE, search: '?email=' + data.email})
        }
    }

    return (
        <div className="ltr main-body leftmenu error-1">
    	<div className="page main-signin-wrapper">
			<div className="row signpages text-center">
				<div className="col-md-12">
					<div className="card">
						<div className="row row-sm">
							<div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
								<div className="mt-5 pt-4 p-2">
									<img src={img1} className="d-lg-none header-brand-img text-start float-start mb-4 error-logo-light" alt="logo"/>
									<img src={img2} className=" d-lg-none header-brand-img text-start float-start mb-4 error-logo" alt="logo"/>
									<img src={img3} className="ht-100 my-auto position-relative" alt="user"/>
								</div>
							</div>
							<div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
								<div className="main-container container-fluid">
									<div className="row row-sm">
										<div className="card-body mt-2 mb-2">
											<img src={img4} className=" d-lg-none header-brand-img text-start float-start mb-4" alt="logo"/>
											<div className="clearfix"></div>
                                            <form onSubmit={handleSubmit(onSubmit)}>
                                                <h5 className="text-start mb-3">{t('labels.password_recovery')}</h5>
                                                <div className="form-group text-start">
													<label>{t('labels.enter_email')}</label>
                                                    <input className="form-control2" type="email" {...register("email")} required/>
                                                </div>
                                                <div className="mb-10">
                                                    <button className="btn ripple btn-main-primary btn-block">{t('labels.send_code')}</button>
                                                </div>
                                                <div className="text-center">
                                                    <NavLink to={LOGIN_PAGE_ROUTE}>{t('labels.cancel')}</NavLink>
                                                </div>
                                            </form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    );
};

export default ForgotPassword;