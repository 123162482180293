import http from "./http-common"
import {postData, getData} from "../handlers";

export default class AuthServices {
    static logIn = (data) => {
        return postData(http, '/auth/login', data);
    }

    static logOut = () => {
        return postData(http, '/auth/logout');
    }

    static profile = () => {
        return getData(http, `/auth/profile`);
    }

    static sendCode = (data) => {
        return postData(http, '/auth/sendCode', data);
    }

    static checkCode = (data) => {
        return postData(http, '/auth/checkCode', data);
    }

    static updatePassword = (data) => {
        return postData(http, '/auth/updatePassword', data);
    }

    static update = (data) => {
        return postData(http, '/auth/update', data);
    }
}