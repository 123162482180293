import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import FormGroup from '../../components/simple/FormGroup';
import UserServices from '../../services/admin/UserServices';
import useFetchInfo from "../../hooks/useFetchInfo.hook";
import useUpdate from '../../hooks/useUpdate.hook';
import { adminRoutes } from '../../navigation/Constants';
import ConfirmActionModal from '../../components/complex/ConfirmActionModal';
import MultiSelect from '../../components/ui/MultiSelect';
import Dictionaries from '../../services/admin/dictionaries';
import { toast } from 'react-toastify';
import UpdatePasswordModal from '../../components/simple/UpdatePasswordModal';

const UserEdit = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {info, loading: fetchLoading} = useFetchInfo(UserServices.view, id);

    const {onSubmit, loading} = useUpdate(UserServices.update, id);

    const [removeModalIsOpen, setRemoveModalIsOpen] = useState();

    const [updatePasswordModalIsOpen, setUpdatePasswordModalIsOpen] = useState();
    const [passwordMessage, setPasswordMessage] = useState();

    useEffect(() => {
        if (info) {
          const {roles, ...rest} = info
          for (const [key, value] of Object.entries(rest)) {
            rest[key] !== null && setValue(key, String(value));
          }
          if(roles){
            let newOptions = roles.map(item => {
              return item.name
            });
            setValue('role', newOptions)
          }
        }
    }, [info, setValue])

    const [loadingRoles, setLoadingRoles] = useState();
    const [roles, setRoles] = useState();
  
    const fetchRoles = useCallback(async () => {
      setLoadingRoles(true);
  
      try {
          const res = await Dictionaries.roles();
  
          setRoles(res.content);
      } catch (e) {
          toast.error(e.message)
      }
  
      setLoadingRoles(false);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    useEffect(() => {
      fetchRoles();
    }, [fetchRoles]);

    const CustomSubmit = (data) => {
        onSubmit(data)
    }

    const handleRemove = (e) => {
      e.preventDefault();
      setRemoveModalIsOpen(true);
    }

    const handlePasswordReset = (e) => {
      e.preventDefault();
      setUpdatePasswordModalIsOpen(true);
  }

  return (
    <div className="main-container container-fluid">
      <div className="inner-body">
        <div className="page-header">
          <div>
            <h2 className="main-content-title tx-24 mg-b-5">{t('sidemenu.users')}</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to={adminRoutes.users.index}>{t('titles.all_users')}</NavLink></li>
              <li className="breadcrumb-item active" aria-current="page">{t('labels.edit')}</li>
            </ol>
          </div>
        </div>
        <div className="row row-sm">
          <div className="col-md-12 col-lg-12">
            <div className="card custom-card">
              <div className="card-header  border-bottom-0 pb-0">
                <div>
                  <div className="d-flex">
                    <label className="main-content-label my-auto pt-2">{t('labels.edit_user')}</label>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form className="form-horizontal" onSubmit={handleSubmit(CustomSubmit)}>
                    <FormGroup label={t('labels.phone')}>
                      <input {...register('phone')} type="tel" className={errors.email ? "form-control2 error" : "form-control2"} placeholder={t('labels.phone')}/>
                    </FormGroup>
                    <FormGroup label={t('labels.roles')}>
                        <Controller name="role" control={control}
                                    render={({field: {onChange, value}}) => (
                                        <MultiSelect isMulti $small options={roles} onChange={onChange}
                                                        value={value}
                                                        isLoading={loadingRoles}
                                                        isDisabled={loadingRoles}
                                            />
                                    )}
                        />
                    </FormGroup>
                    {passwordMessage && <div className="bg-secondary pd-lg-10 mb-3">
                                    <div>{t('labels.new_password')}: {passwordMessage}</div>
                                </div>}
                    <div className='row justify-content-end'>
                      <button type="button" className="btn my-2 btn-outline-primary me-2" onClick={handleRemove}>
                        <i className="ti-trash me-2"></i> {t('labels.delete')}
                      </button>
                      <button className="btn btn-secondary my-2 btn-icon-tex me-2" onClick={handlePasswordReset}>
                        {t('labels.password_reset')}
                      </button>
                      <button type="submit" className="btn btn-primary my-2 btn-icon-tex me-2">
                         {t('labels.save')}
                      </button>
                    </div>
                </form>                
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmActionModal
                isOpen={removeModalIsOpen}
                closeModal={() => setRemoveModalIsOpen(false)}
                onRequestClose={() => setRemoveModalIsOpen(false)}
                successCallback={() => {
                    setRemoveModalIsOpen(false);
                    navigate(adminRoutes.users.index)
                }}
                method={UserServices.delete}
                data={id}
                content={{
                    title: t('messages.remove_message'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
            <UpdatePasswordModal
                isOpen={updatePasswordModalIsOpen}
                closeModal={() => setUpdatePasswordModalIsOpen(false)}
                onRequestClose={() => setUpdatePasswordModalIsOpen(false)}
                setPasswordMessage={setPasswordMessage}
                successCallback={() => {
                    setUpdatePasswordModalIsOpen(false)
                }}
                method={UserServices.resetPassword}
                data={id}
                content={{
                    title: t('modals.update_password.title'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
    </div>
  )
}

export default UserEdit