import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ModalComponent from '../../components/complex/ModalComponent';
import FormGroup from '../../components/simple/FormGroup';
import UserServices from '../../services/admin/UserServices';

const ReplenishBalanceModal = ({closeModal, successCallback, id, ...props}) => {
    const [loading, setLoading] = useState();

    const {t} = useTranslation();

    const {handleSubmit, register, getValues, reset, control, formState: {errors}} = useForm();

    const CustomSubmit = async (data) => {
        const res = await UserServices.replenishBalance(id, data)

        if(res.statusCode === 200){
          toast.success(res.message);
          successCallback();
        }else{
          toast.error(res.message)
        }
        reset();
        closeModal();
    }

      
  return (
        <ModalComponent
            {...props} closeModal={closeModal} ariaHideApp={false}>
            <div className={loading ? 'isLoading' : ''} style={{width: "600px", maxWidth: "100%"}}>
                <h4 className="headline-4">{t('labels.replenish_balance')}</h4>
                <form onSubmit={handleSubmit(CustomSubmit)}>
                    <FormGroup label={t('labels.summa')}>
                        <input {...register('sum')} type="text" className="form-control2" placeholder={t('labels.summa')}/>
                    </FormGroup>
                    <div className='row justify-content-end'>
                      <div className='col-auto'>
                        <button className="btn btn-primary" type='submit'>
                          {t('labels.replenish')}
                        </button>
                      </div>
                    </div>
                </form>
            </div>
        </ModalComponent>
  )
}

export default ReplenishBalanceModal