import {useState} from "react";
import { useNavigate} from "react-router-dom";
import { toast } from "react-toastify";

const useUpdate = (method, id, subId = null, goBack = true, callback = null) => {
    const [loading, setLoading] = useState();
    const history = useNavigate();
    const onSubmit = async (data) => {
        setLoading(true)
        const res = subId ? await method(id, subId, data) : id ? await method(id, data) : await method(data);

        setLoading(false);
        
        res.statusCode === 200 ? toast.success(res.message) : toast.error(res.message)

        if (res.statusCode === 200) {
            //history.goBack();
            callback && callback(res)
        }
    }

    return {
        onSubmit,
        loading
    };
};

export default useUpdate;