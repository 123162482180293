import http from "./http-common"
import {deleteData, getData, postData} from "../handlers";

export default class UserServices {
    static index = (data, queryString) => {
        return getData(http, `/users${data}${queryString ? `&${queryString}` : ""}`);
    }

    static view = (id, queryString) => {
        return getData(http, `/users/${id}${queryString ? `${queryString}` : ""}`);
    }

    static store = (data) => {
        return postData(http, `/users/store`, data);
    }

    static update = (id, data) => {
        return postData(http, `/users/${id}`, data);
    }

    static delete = (id) => {
        return deleteData(http, `/users/${id}`);
    }

    static resetPassword = (id) => {
        return postData(http, `/users/${id}/resetPassword`);
    }

    static replenishBalance = (id, data) => {
        return postData(http, `/users/${id}/replenishBalance`, data);
    }

    static renewSubscription = (id, data) => {
        return postData(http, `/users/${id}/renewSubscription`, data);
    }

    static changeStatus = (id) => {
        return postData(http, `/users/${id}/changeStatus`);
    }
}