import http from "./http-common"
import {getData} from "../handlers";

export default class Dictionaries {
    static roles = async () => {
        const res = await getData(http,`/dictionaries/roles`);
        return this.transformToOptions(res)
    }

    static subscriptionTypes = async (data) => {
        const res = await getData(http,`/dictionaries/subscriptionTypes${data?.queryString ? `?${data.queryString}` : ""}`);
        return this.transformToOptions(res)
    }

    static transformToOptions = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {name, id, ...rest} = item;

            return {
                label: name,
                value: String(id),
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformAvialableFieldsToOptions = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {title, slug, ...rest} = item;

            return {
                label: title,
                value: slug,
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformBankbooksToOptions = ({content, ...props}) => {
        const newContent = content.map((item) => {
            const {number, id, ...rest} = item;

            return {
                label: number,
                value: String(id),
                ...rest
            }
        })

        return {
            ...props,
            content: newContent
        }
    }

    static transformBankbooksToPaginateOptions = ({content, ...props}) => {
        const {items, current_page, total_pages} = content;
        const newItems = items.map((item) => {
            const {number, id, ...rest} = item;

            return {
                label: number,
                value: String(id),
                ...rest
            }
        })

        const newContent = {
            hasMore: total_pages > current_page,
            options: newItems
        }

        return {
            ...props,
            content: newContent
        }
    }
}