import React from 'react'

const FullScreen = () => {
	const toggleFullScreen = () => {
		document.documentElement.classList.add("fullscreen");
		if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
			if (document.documentElement.requestFullScreen) {
				document.documentElement.requestFullScreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullScreen) {
				document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen();
			}
		} else {
			document.documentElement.classList.remove('fullscreen');
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	}

  return (
    <div className="dropdown" onClick={() => toggleFullScreen()}>
		<a className="nav-link icon full-screen-link">
			<i className="fe fe-maximize fullscreen-button fullscreen header-icons"></i>
			<i className="fe fe-minimize fullscreen-button exit-fullscreen header-icons"></i>
		</a>
	</div>
  )
}

export default FullScreen