import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MAIN_PAGE_ROUTE } from '../../navigation/Constants'
import FullScreen from '../simple/FullScreen'
import ThemeSwitch from '../simple/ThemeSwitch'
import ProfileDropdown from './ProfileDropdown'

const Header = () => {
	const [hidden, setHidden] = useState();

	const sidemenuChange = (e) => {
		e.preventDefault();
		if(hidden){
			document.getElementById('main-body').classList.remove('main-sidebar-hide')
		}else{
			document.getElementById('main-body').classList.add('main-sidebar-hide')
		}
		setHidden(!hidden)		
	}
	
  return (
	<>
		<div className="main-header side-header sticky">
			<div className="main-container container-fluid">
				<div className="main-header-left">
					<div className="main-header-menu-icon" id="mainSidebarToggle" onClick={(e) => sidemenuChange(e)}><span></span></div>
					<div className="hor-logo">
						<NavLink className="main-logo" to={MAIN_PAGE_ROUTE}>
								<img src="../assets/img/brand/logo.png" className="header-brand-img desktop-logo" alt="logo"/>
								<img src="../assets/img/brand/logo-light.png" className="header-brand-img desktop-logo-dark" alt="logo"/>
						</NavLink>
					</div>
				</div>
				<div className="main-header-right">
						<button className="navbar-toggler navresponsive-toggler" type="button" data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4"
							aria-expanded="false" aria-label="Toggle navigation">
							<i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
						</button>
						<div className="navbar navbar-expand-lg  nav nav-item  navbar-nav-right responsive-navbar navbar-dark  ">
							<div className="collapse navbar-collapse" id="navbarSupportedContent-4">
								<div className="d-flex order-lg-2 ms-auto">
									<ThemeSwitch/>
									<FullScreen/>
									<ProfileDropdown/>
								</div>
							</div>
						</div>
				</div>
			</div>
		</div>
	</>
  )
}

export default Header