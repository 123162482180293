import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import useFetchInfo from '../../hooks/useFetchInfo.hook';
import useUpdate from '../../hooks/useUpdate.hook';
import { useAuth } from '../../pages/login/ProvideAuth';
import AuthServices from '../../services/admin/AuthServices';
import UserServices from '../../services/admin/UserServices';
import FormGroup from './FormGroup';
import UpdatePasswordModal from './UpdatePasswordModal';

const ProfileContainer = () => {
    const {t} = useTranslation();
    const {userData, setInfo} = useAuth();

    const {handleSubmit, register, watch, setValue, control, formState: {errors}} = useForm();

    const {info, loading: fetchLoading} = useFetchInfo(AuthServices.profile);

    const [updatePasswordModalIsOpen, setUpdatePasswordModalIsOpen] = useState();
    const [passwordMessage, setPasswordMessage] = useState();

    useEffect(() => {
        if (info) {
          const {...rest} = info
          for (const [key, value] of Object.entries(rest)) {
            rest[key] !== null && setValue(key, String(value));
          }
        }
    }, [info, setValue])

    const successCallback = res => {
        const newInfo = {
            ...userData,
            name: res?.content?.name
        }

        setInfo(newInfo)
    }

    const {onSubmit, loading} = useUpdate(AuthServices.update, null, null, false, successCallback)


    const handlePasswordReset = (e) => {
        e.preventDefault();
        setUpdatePasswordModalIsOpen(true);
    }

    
  return (
    <div className="main-container container-fluid">
            <div className="inner-body">
                <div className="page-header">
                    <div>
                        <h2 className="main-content-title tx-24 mg-b-5">{t('labels.profile')}</h2>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">{t('labels.profile')}</li>
                        </ol>
                    </div>
                </div>
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card card-body">
                            <form className="form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                                <FormGroup label={t('labels.name') + '*'}>
                                    <input {...register('name', {required: true})} type="text" className={errors.email ? "form-control2 error" : "form-control2"} placeholder={t('labels.name')}/>
                                </FormGroup>
                                <FormGroup label={t('labels.email') + '*'}>
                                    <input {...register('email', {required: true})} type="email" className={errors.email ? "form-control2 error" : "form-control2"} placeholder={t('labels.email')}/>
                                </FormGroup>
                                <FormGroup label={t('labels.phone')}>
                                    <input {...register('phone')} type="tel" className={errors.email ? "form-control2 error" : "form-control2"} placeholder={t('labels.phone')}/>
                                </FormGroup>
                                {passwordMessage && <div className="bg-secondary pd-lg-10 mb-3">
                                    <div>{passwordMessage}</div>
                                </div>}
                                <div className='row justify-content-end'>
                                    <button onClick={handlePasswordReset} className="btn btn-secondary my-2 btn-icon-tex me-2">
                                        {t('labels.password_reset')}
                                    </button>
                                    <button type="submit" className="btn btn-primary my-2 btn-icon-tex me-2">
                                        {t('labels.save')}
                                    </button>
                                </div>
                            </form>   
                        </div>
                    </div>
                </div>
            </div>
            <UpdatePasswordModal
                isOpen={updatePasswordModalIsOpen}
                closeModal={() => setUpdatePasswordModalIsOpen(false)}
                onRequestClose={() => setUpdatePasswordModalIsOpen(false)}
                setPasswordMessage={setPasswordMessage}
                successCallback={() => {
                    setUpdatePasswordModalIsOpen(false)
                }}
                method={UserServices.resetPassword}
                data={userData?.id}
                content={{
                    title: t('modals.update_password.title'),
                    yes: t('labels.yes'),
                    no: t('labels.cancel')
                }}
            />
    </div>
  )
}

export default ProfileContainer